import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.titles,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,[_vm._v("Заголовки страниц")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.create}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v("Добавить")],1)],1)]},proxy:true},{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'DD.MM.YYYY HH:mm'))+" ")]}},{key:`item.page`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.PAGE_TITLE[item.page])+" ")]}},{key:`item.edit`,fn:function({ item }){return [_c(VIcon,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.edit(item._id)}}},[_vm._v("mdi-pencil")])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }