<template>
    <v-data-table
        :headers="headers"
        :items="titles"
        class="elevation-1"
        hide-default-footer
        disable-pagination
    >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Заголовки страниц</v-toolbar-title>
                <v-spacer />
                <v-btn color="primary" @click="create">
                    <v-icon>mdi-plus</v-icon>Добавить</v-btn>
            </v-toolbar>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | moment('DD.MM.YYYY HH:mm') }}
        </template>
        <template v-slot:[`item.page`]="{ item }">
            {{ PAGE_TITLE[item.page] }}
        </template>
        <template v-slot:[`item.edit`]="{ item }">
            <v-icon color="primary" @click="edit(item._id)">mdi-pencil</v-icon>
        </template>
    </v-data-table>
</template>

<script>
    import store from '../../store';
    import { mapState } from 'vuex';

    import { PAGE_TITLE } from '../../vars';

    export default {
        name: 'TitlesList',
        components: {
        },
        data: () => ({
            PAGE_TITLE,
            headers: [
                { text: 'Страница показа', value: 'page' },
                { text: '', align: 'right', value: 'edit' },
            ],
        }),
        computed: {
            ...mapState('titles', {
                titles: state => state.entities
            }),
        },
        async mounted() {
            this.fetch();
        },
        methods: {
            async fetch() {
                await store.dispatch('titles/fetch', {});
            },
            async create() {
                await this.$router.push({ name: 'titles-create' });
            },
            async edit(id) {
                await this.$router.push({ name: 'titles-edit', params: { id } });
            }
        }
    }
</script>